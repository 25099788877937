<template>
  <div class="shopee-container">
    <div class="queryItems mb10">
      <el-input v-model="shopName" size="mini" class="mr15" clearable style="width: 30rem" placeholder="店铺名称"></el-input>
      <el-select size="mini" class="mr15" v-model="shopType" placeholder="请选择店铺类型">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" @click="getList">查询</el-button>
      <el-button size="mini" type="primary" @click="empower">授权</el-button>
    </div>
    <el-table
        :data="listData"
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-text="获取订单数据中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >

      <el-table-column label="店铺名称" prop="ElectronicShopName" align="center"></el-table-column>
      <el-table-column label="店铺类型" prop="ShopTypeTxt" align="center"></el-table-column>
      <el-table-column label="目的地" prop="DestinationIdTxt" align="center"></el-table-column>
      <el-table-column label="仓库" prop="WareHouseIdTxt" align="center"></el-table-column>
      <el-table-column label="承运商" prop="CarrierIdTxt" align="center"></el-table-column>
      <el-table-column label="货物类型" prop="GoodsTypeIdTxt" align="center"></el-table-column>
      <el-table-column label="时效" prop="TimerIdTxt" align="center"></el-table-column>
      <el-table-column label="发件人" prop="SenderName" align="center"></el-table-column>
      <el-table-column
          label="操作"
          align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="getOrder(scope.row)">获取订单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="授权"
        :visible.sync="visible"
        width="450px"
        center
        :close-on-click-modal="false"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="店铺名称">
          <el-input size="mini" style="width: 270px" disabled v-model="authParams.shop_name"></el-input>
        </el-form-item>
        <el-form-item label="店铺ID">
          <el-input size="mini" style="width: 270px" disabled v-model="authParams.shop_id"></el-input>
        </el-form-item>
        <el-form-item label="目的地" prop="DestinationId">
          <el-select size="mini" style="width: 270px" v-model="ruleForm.DestinationId" placeholder="请选择目的地">
            <el-option :label="item.Name" :value="item.Id" :key="item.Id" v-for="item in paramsOptions['destination']"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库" prop="WareHouseId">
          <el-select size="mini" style="width: 270px" v-model="ruleForm.WareHouseId" placeholder="请选择仓库">
            <el-option :label="item.Name" :value="item.Id" :key="item.Id" v-for="item in paramsOptions['warehouse']"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="承运商" prop="CarrierId">
          <el-select size="mini" style="width: 270px" v-model="ruleForm.CarrierId" placeholder="请选择承运商">
            <el-option :label="item.Name" :value="item.Id" :key="item.Id" v-for="item in paramsOptions['carrier']"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货物类型" prop="GoodsTypeId">
          <el-select size="mini" style="width: 270px" v-model="ruleForm.GoodsTypeId" placeholder="请选择货物类型">
            <el-option :label="item.Name" :value="item.Id" :key="item.Id" v-for="item in paramsOptions['goodsType']"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时效" prop="TimerId">
          <el-select size="mini" style="width: 270px" v-model="ruleForm.TimerId" placeholder="请选择时效">
            <el-option :label="item.Name" :value="item.Id" :key="item.Id" v-for="item in paramsOptions['timer']"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收件人" prop="SenderName">
          <el-input size="mini" style="width: 270px" v-model="ruleForm.SenderName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submitForm('ruleForm')">立即授权</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import {addShopee, authShopee, getShopeeList, getShopeeOrder, shopeeEmpower} from "@/api/member";
import { mapGetters } from "vuex"

export default defineComponent({
  data() {
    return {
      shopName: "",
      listData: [],
      shopeeInfo: {},
      paramsOptions: {
        timer: [],
        carrier: [],
        goodsType: [],
        warehouse: [],
        destination: []
      },
      rules: {
        DestinationId: [
          { required: true, message: '请选择目的地', trigger: 'change' }
        ],
        WareHouseId: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ],
        CarrierId: [
          { required: true, message: '请选择承运商', trigger: 'change' }
        ],
        TimerId: [
          { required: true, message: '请选择时效', trigger: 'change' }
        ],
        GoodsTypeId: [
          { required: true, message: '请选择货物类型', trigger: 'change' }
        ],
        SenderName: [
          { required: true, message: '请输入收件人', trigger: 'blur' },
        ],
      },
      ruleForm: {},
      loading: false,
      shopId: undefined,
      code: undefined,
      shopType: 0,
      visible: false,
      pageIndex: 1,
      authParams: {},
      pageSize: 10,
      options: [
        {
          value: 0,
          label: '不限'
        },
        {
          value: 1,
          label: 'Shopee'
        },
        {
          value: 2,
          label: 'Lazada'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([])
  },
  created() {
    this.getList()
    this.parseQueryParams()
    if (this.code && this.shopId) {
      this.replaceState()
      this.authShopee().then(() => {
        this.init().then(() => {
          this.visible = true
        })
      })
    }
  },
  methods: {
    replaceState() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const path = url.pathname + url.hash;
      if (currentUrl !== window.location.origin + path) {
        window.history.replaceState(null, '', path);
      }
    },
    init() {
      return new Promise((resolve) => {
        this.$store.dispatch('webSite/getTimer').then(data => {
          this.paramsOptions['timer'] = data
        })
        this.$store.dispatch('webSite/getCarrier').then(data => {
          this.paramsOptions['carrier'] = data
        })
        this.$store.dispatch('webSite/getGoodsType').then(data => {
          this.paramsOptions['goodsType'] = data
        })
        this.$store.dispatch('webSite/getWareHouse').then(data => {
          this.paramsOptions['warehouse'] = data
        })
        this.$store.dispatch('webSite/getCountry').then(data => {
          this.paramsOptions['destination'] = data
        })
        resolve(true)
      })
    },
    submitForm(formName) {
      console.log(111)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            ShopType: 1,
            ElectronicShopId: this.authParams.shop_id,
            ElectronicShopName: this.authParams.shop_name,
            ShopeeDestination: this.authParams.country,
            DestinationId: this.ruleForm.DestinationId,
            WareHouseId: this.ruleForm.WareHouseId,
            CarrierId: this.ruleForm.CarrierId,
            GoodsTypeId: this.ruleForm.GoodsTypeId,
            TimerId: this.ruleForm.TimerId,
            SenderName: this.ruleForm.SenderName,
            AuthorizationTime: this.authParams.ShopAuth_time,
            ShopExpireTime: this.authParams.ShopExpire_time,
            AccessToken: this.authParams.access_token,
            RefreshToken: this.authParams.refresh_token,
            TokenExpireIn: this.authParams.expire_in,
          }
          addShopee(data).then(response => {
            const { Code, Msg } = response
            if (Code === 200) {
              this.$message.success("绑定成功！！")
              this.visible = false
              this.getList()
            } else {
              this.$message.warning(Msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    authShopee() {
      const data = {
        Code: this.code,
        ShopeeId: this.shopId
      }
      return new Promise((resolve, reject) => {
        authShopee(data).then(response => {
          const { Code, Msg, Data } = response
          if (Code === 200) {
            this.authParams = Data
            resolve(true)
          } else {
            reject(Msg)
          }
        })
      })
    },
    getOrder(row) {
      const data = {
        ShopeeId: row.ElectronicShopId,
        IsTbSystem: true
      }
      this.loading = true
      getShopeeOrder(data).then(response => {
        this.loading = false
        const { Code, Msg } = response
        if (Code === 200) {
          this.$message.success("获取订单成功！！")
        } else {
          this.$message.warning(Msg)
        }
      }).catch(() => { this.loading = false })
    },
    getList() {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        ShopType: this.shopType,
        ElectronicShopId: undefined,
        ElectronicShopName: this.shopName
      }
      getShopeeList(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    parseQueryParams() {
      const queryString = window.location.search.substring(1);
      const params = new URLSearchParams(queryString);
      this.code = params.get('code');
      this.shopId = params.get('shop_id');
    },
    empower() {
      const data = {
        RedirectUrl: encodeURIComponent(`${window.location.origin}/#/member/shopee/shop`)
      }
      shopeeEmpower(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          window.location.href = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  }
})
</script>



<style scoped lang="scss">
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.shopee-container {
  padding: 2rem 2.5rem;
  .queryItems {
    display: flex;
    align-items: center;
  }
}
</style>